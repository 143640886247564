import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logoimage from "../image/vasu.png";

const Header = () => {
    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
        localStorage.removeItem("hospital_id");
        localStorage.removeItem("user");
        navigate(`/`, {});
    };
    return (
        <>
            <header className="site-header site-header--menu-right site-header--absolute">
                <div className="container-fluid pr-lg-9 pl-lg-9">
                    <nav className="navbar site-navbar offcanvas-active navbar-expand-lg  px-0">

                        <div className="brand-logo">
                            <a href="#">
                                <img src={logoimage} className="logowidth" />
                            </a>
                        </div>
                        <div className="collapse navbar-collapse" id="mobile-menu">
                            <div className="navbar-nav-wrapper">
                                <ul className="navbar-nav main-menu">
                                    <li className="nav-item">
                                        <span className="nav-link" role="button" aria-expanded="false">Home</span>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#courses" role="button" aria-expanded="false">Courses</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#about" role="button" aria-expanded="false">About VASU</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://enpro.co.in/" target="_blank" role="button" aria-expanded="false">About ENPRO</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#contact" role="button" aria-expanded="false">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            <button className="d-block d-lg-none offcanvas-btn-close" type="button" data-toggle="collapse" data-target="#mobile-menu" aria-controls="mobile-menu" aria-expanded="true" aria-label="Toggle navigation">
                                <i className="gr-cross-icon"></i>
                            </button>
                        </div>
                        <div className="header-btn  ml-auto ml-lg-5 mr-6 mr-lg-0 d-none d-xs-block">
                            <a className="btn btn-primary gr-text-9 d-none d-xs-inline-flex "  target="_blank"  href="https://join.vasutsdc.in/">
                                Login / Register
                            </a>
                        </div>
                        <button className="navbar-toggler btn-close-off-canvas  hamburger-icon border-0" type="button" data-toggle="collapse" data-target="#mobile-menu" aria-controls="mobile-menu" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="hamburger hamburger--squeeze js-hamburger">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </span>
                        </button>
                    </nav>
                </div>
            </header>


        </>
    );
};

export default Header;
