import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';

import adminLayout from "../hoc/adminLayout";
import logoimage from "../image/vasu.png";
import axios from "axios";
import baseURL from "../common/base-urls.js";
import "react-select2-wrapper/css/select2.css";
import debounce from "lodash/debounce";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";
import pareshmevawala from "../image/vasutraining/MrPareshMevawala.jpg";
import vasutrainingcenter1 from "../image/vasutraining/10.jpg";
import vasutrainingcenter2 from "../image/vasutraining/9.jpg";
import vasutrainingcenter3 from "../image/vasutraining/3.jpg";
import vasutrainingcenter4 from "../image/vasutraining/8.jpg";
import CourseSlider from "./courseSlider.js";
import otherPage from "../hoc/otherpage.js";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [apiUrl, setApiUrl] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    console.log(`=======================================`);
    console.log(`========= Updated on ${formattedTime} =========`);
    console.log(`=======================================`);
    console.log(location);
    return () => {
    };
  }, [location]);
  //#region ====================================================== Create State =====================================================
  const [state, setState] = useState({
    delete_id: "0",
    lblpagedisplay: 0,
    total_pages: 0,
    pagination: [],
    show_filter: false,
    filter_applied: false,
    show_toast: false,
    toast_msg: "No record found",
    show_modal: false,
    modal_title: "Confirmation",
    modal_msg:
      "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
    modal_button: "Delete",
  });
  //#endregion
  //#region ====================================================== Update State ======================================================
  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  //#endregion  
  useEffect(() => {
    document.querySelectorAll('a[href^="/"], a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        const href = this.getAttribute('href');
        const id = href.startsWith('#') ? href.substring(1) : href; // Remove the leading slash or hash

        if (id === '/privacy' || id === '/terms') {
          // Navigate to privacy or terms page
          window.location.href = window.location.origin + '#/' + id.substring(1);
          // Scroll to the top of the page
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
        else if (id === '/') {
          // Navigate to privacy or terms page
          window.location.href = window.location.origin;
          // Scroll to the top of the page
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
        else if (id !== '') {
          // Scroll to the element with the corresponding ID
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth'
            });
          }
        } else {
          // Handle routing for React Router links
          window.location.href = href;
        }
      });
    });
  }, []);

  return (
    <>
      <div className="inner-banner bg-default-2 pt-29 pb-6">
        <div className="container">
          <div className="row  justify-content-center pt-5">
            <div className="col-xl-8 col-lg-9">
              <div className="px-md-15 text-center">
                <h2 className="title gr-text-2 mb-9">Privacy Policy</h2>
                <p className="gr-text-8 mb-13">At VASU Training and Skill Development Center, we are committed to protecting your privacy and safeguarding your personal information. This privacy policy outlines how we collect, use, and disclose the information you provide to us when using our website and participating in our courses.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-block bg-default-2 pb-21">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="px-12">
                <div className="single-block mb-11">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Information We Collect</h3>
                  <p className="gr-text-9">When you register for a course on our website, we collect personal information such as your name, email address, phone number, and any other details necessary for enrollment. We may also collect non-personal information such as demographic data and website usage statistics through cookies and similar technologies.</p>
                </div>
                <div className="single-block mb-13">
                  <h3 className="gr-text-8 font-weight-bold pb-3">How We Use Your Information</h3>
                  <p className="gr-text-9">The personal information we collect is used to process your course registration, communicate with you regarding your enrollment, and provide you with the necessary course materials and updates. We may also use your information to improve our website, customize your learning experience, and send you relevant promotional offers or educational content.</p>
                </div>
                <div className="single-block mb-13">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Disclosure of Information</h3>
                  <p className="gr-text-9">We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or to fulfill the purposes outlined in this privacy policy. Your personal information may be shared with our trusted service providers, such as payment processors and email service providers, to facilitate course enrollment and communication.</p>
                </div>
                <div className="single-block mb-13">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Data Security</h3>
                  <p className="gr-text-9">We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. Despite our best efforts, no method of transmission over the internet or electronic storage is completely secure. Therefore, while we strive to protect your data, we cannot guarantee its absolute security.</p>
                </div>
                <div className="single-block mb-13">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Your Choices</h3>
                  <p className="gr-text-9">You have the right to access, update, or delete your personal information at any time by contacting us directly. You may also choose to opt-out of receiving promotional communications from us by following the unsubscribe instructions included in our emails.</p>
                </div>
                <div className="single-block mb-13">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Children's Privacy</h3>
                  <p className="gr-text-9">Our website and services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children. If you are a parent or guardian and become aware that your child has provided us with personal information, please contact us immediately.</p>
                </div>
                <div className="single-block mb-13">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Changes to This Policy</h3>
                  <p className="gr-text-9">We reserve the right to update or modify this privacy policy at any time. Any changes will be posted on this page, and the effective date will be updated accordingly.</p>
                </div>
                <div className="single-block mb-13">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Contact Us</h3>
                  <p className="gr-text-9">If you have any questions or concerns about our privacy practices or this privacy policy, please contact us at <a href="mailto:trainings@vasutsdc.in" className="gr-text-color gr-hover-text-green">trainings@vasutsdc.in</a>.</p>
                </div>
                <div className="single-block pb-11">
                  <p className="gr-text-9 pb-5 mb-0">By using our website and participating in our courses, you consent to the collection, use, and disclosure of your personal information as described in this privacy policy.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default otherPage(PrivacyPolicy); 
