
// import './assets/css/bootstrap.min.css';  
// import './assets/css/select2.css'; 
// import './assets/js/bundle.js'; 
// import './assets/js/scripts.js';  
// import './assets/js/select2.min.js';    
import Index from './pages/index.js'
import PrivacyPolicyPage from './pages/privacyPolicy.js'
import TermsPage from './pages/terms.js'
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
function App() {
    return (
        <Router>
            <Routes>
                {/* <Route exact path='/login' element={<Login />} /> */}
                <Route exact path='/' element={<Index />} />
                <Route exact path='/privacy' element={<PrivacyPolicyPage />} />
                <Route exact path='/terms' element={<TermsPage />} />

            </Routes>
        </Router>
    )
}

export default App;
