import React from "react";
import logoimage from "../image/vasu.png";
import { Link } from 'react-router-dom';
class Footer extends React.Component {
    componentDidMount() {
        var navLinks = document.querySelectorAll('.nav-link');

        // Add a click event listener to each link
        navLinks.forEach(function (link) {
            link.addEventListener('click', function (event) {
                // Remove "active" class from all links
                navLinks.forEach(function (navLink) {
                    navLink.classList.remove('active');
                });

                // Add "active" class to the clicked link
                link.classList.add('active');
            });
        });

        // document.querySelectorAll('.nav-link').forEach(function (link) {
        //     link.addEventListener('click', function (event) {
        //         event.preventDefault(); // Prevent the default link behavior

        //         var targetId = this.getAttribute('href').substr(1); // Get the target element's ID

        //         var targetElement = document.getElementById(targetId); // Get the target element

        //         if (targetElement) {
        //             var offset = 130; // Set your desired offset (in pixels)
        //             var targetOffset = targetElement.getBoundingClientRect().top + window.scrollY - offset;

        //             window.scrollTo({
        //                 top: targetOffset,
        //                 behavior: 'smooth' // Optional: Add smooth scrolling
        //             });
        //         }
        //     });
        // });
    }

    render() {
        return (
            <>
                <div className="cta-section bg-green dark-mode-texts pt-12 pb-13 pt-md-15 pb-md-15 ">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-10">
                                <div className="cta-text d-flex flex-column flex-sm-row align-items-center justify-content-center" data-aos="fade-right" data-aos-duration="700" data-aos-once="true">
                                    <h2 className="title gr-text-5 mb-6 mb-sm-0 mr-sm-9 ">Ready to get started?</h2>
                                    <div className="cta-btn" data-aos="zoom-in" data-aos-duration="700" data-aos-delay="500" data-aos-once="true">
                                        <a href="https://join.vasutsdc.in/#/register" target="_blank" className="btn btn-white bg-white gr-hover-y">Enroll Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="contact" className="footer-section pt-15 pt-lg-25 pb-lg-21 dark-mode-texts bg-blackish-blue">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-4">
                                <div className="footer-logo mb-11">
                                    <img src={logoimage} className="logowidth" />

                                </div>
                                <p className="gr-text-11">
                                    Join us at VASU Training and Skill Development Center and unlock your potential in the environmental, health, and safety industry!
                                </p>
                                <p className="copyright-text gr-text-11 mb-6 mb-lg-0 gr-text-color text-center text-lg-left">
                                    © 2024 Copyright, All Right Reserved.
                                </p>
                                <ul className="social-icons py-7 list-unstyled mb-7 mb-lg-0">
                                    <li className="mr-2"><a href="https://www.facebook.com/profile.php?id=61556836856270" className="text-storm gr-hover-text-green"><i className="icon icon-logo-facebook"></i></a></li>
                                    <li className="mr-2"><a href="https://www.instagram.com/vasu_tsdc_surat/" className="text-storm gr-hover-text-green"><i className="icon icon-instant-camera-2"></i></a></li>
                                </ul>
                            </div>
                            <div className="col-lg-7 offset-lg-1 col-md-8">
                                <div className="row">
                                    <div className="col-xs-8 col-lg-4">
                                        <div className="single-footer mb-13 mb-lg-9">
                                            <p className="footer-title gr-text-11 mb-7">Courses</p>
                                            <ul className="footer-list list-unstyled gr-text-9">
                                                <li className="py-2">
                                                    <Link to="/privacy" className="gr-text-color gr-hover-text-green">Privacy Policy</Link>
                                                </li>
                                                <li className="py-2">
                                                    <Link to="/terms" className="gr-text-color gr-hover-text-green">Terms & Condition</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-xs-8 col-lg-4">
                                        <div className="single-footer mb-13 mb-lg-9">
                                            <p className="footer-title gr-text-11 mb-7"> Address</p>
                                            <p className="gr-text-11">Plot No. D/29/16-17, 3rd Floor, Road No.17, Hojiwala Industrial Estate, Gate No.3, Sachin Palsana Road, Sachin, Surat - 394230</p>

                                        </div>
                                    </div>
                                    <div className="col-xs-8 col-lg-4">
                                        <div className="single-footer mb-13 mb-lg-9">
                                            <p className="footer-title gr-text-11 mb-7">Ready to enhance your environmental, health, and safety career? Reach out to us today:</p>
                                            <ul className="footer-list list-unstyled gr-text-9">
                                            <li className="py-2"><a href="mailto:trainings@vasutsdc.in" className="gr-text-color gr-hover-text-green">trainings@vasutsdc.in</a></li>
                                            <li className="py-2"><a  href="tel:8160483760" className="gr-text-color gr-hover-text-green">+91 8160483760</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        );
    }
}

export default Footer;
