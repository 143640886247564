import React from "react";
import Header2 from './../common/header2';
import Footer from './../common/footer';
import logoimage from "../image/vasu.png";

const otherPage = (ChildComponent) => {
    class OtherPage extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                pageLoaded: false,
                saveLeadClickEvent: ""
            };
        }
        componentDidMount() {
            setTimeout(() => {
                this.setState(() => ({
                    pageLoaded: true
                }))
            }, 1000);
        }
        renderHtml() {
            //if we remove this comment this code will create issue with menu submenu
            // if (!this.state.pageLoaded) {
            //     return <div className="loading-page">
            //         <div className="center">
            //             <img src={logoimage} width="155" height="28" className="preloadercss" />
            //         </div>
            //     </div>
            // }
            return <>
                {/* <div className="preloader">
                    <img src={logoimage}  width="155" height="28"  className="preloadercss" />
                </div> */}
                <div class="site-wrapper overflow-hidden">
                    <Header2 />
                    <ChildComponent {...this.props} />
                    <Footer />
                </div>



            </>
        }

        addLeadModalFooterContent() {
            return <>
                <div >
                    <button onClick={(e) => this.setState(() => ({ saveLeadClickEvent: (Math.random() + 1).toString(36).substring(7) }))} className="btn btn-default low-height-btn">Add Lead</button>
                </div>
            </>;
        }

        handleParentData = (e) => {
            console.log(e);
        }

        render() {
            return <>
                {this.renderHtml()}
            </>
        }
    }

    return OtherPage;
}

export default otherPage;