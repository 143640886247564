import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';

import adminLayout from "../hoc/adminLayout";
import logoimage from "../image/vasu.png";
import axios from "axios";
import baseURL from "../common/base-urls.js";
import "react-select2-wrapper/css/select2.css";
import debounce from "lodash/debounce";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";
import pareshmevawala from "../image/vasutraining/MrPareshMevawala.jpg";
import vasutrainingcenter1 from "../image/vasutraining/10.jpg";
import vasutrainingcenter2 from "../image/vasutraining/9.jpg";
import vasutrainingcenter3 from "../image/vasutraining/3.jpg";
import vasutrainingcenter4 from "../image/vasutraining/8.jpg";
import CourseSlider from "./courseSlider.js";
import otherPage from "../hoc/otherpage.js";

const Terms = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [apiUrl, setApiUrl] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    console.log(`=======================================`);
    console.log(`========= Updated on ${formattedTime} =========`);
    console.log(`=======================================`);
    console.log(location);
    return () => {
    };
  }, [location]);
  //#region ====================================================== Create State =====================================================
  const [state, setState] = useState({
    delete_id: "0",
    lblpagedisplay: 0,
    total_pages: 0,
    pagination: [],
    show_filter: false,
    filter_applied: false,
    show_toast: false,
    toast_msg: "No record found",
    show_modal: false,
    modal_title: "Confirmation",
    modal_msg:
      "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
    modal_button: "Delete",
  });
  //#endregion
  //#region ====================================================== Update State ======================================================
  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  //#endregion   
  useEffect(() => {
    document.querySelectorAll('a[href^="/"], a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        const href = this.getAttribute('href');
        const id = href.startsWith('#') ? href.substring(1) : href; // Remove the leading slash or hash

        if (id === '/privacy' || id === '/terms') {
          // Navigate to privacy or terms page
          window.location.href = window.location.origin + '#/' + id.substring(1);
          // Scroll to the top of the page
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
        else if (id === '/') {
          // Navigate to privacy or terms page
          window.location.href = window.location.origin;
          // Scroll to the top of the page
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
        else if (id !== '') {
          // Scroll to the element with the corresponding ID
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth'
            });
          }
        } else {
          // Handle routing for React Router links
          window.location.href = href;
        }
      });
    });
  }, []);

  return (
    <>
      <div className="inner-banner bg-default-2 pt-29 pb-6">
        <div className="container">
          <div className="row  justify-content-center pt-5">
            <div className="col-xl-8 col-lg-9">
              <div className="px-md-15 text-center">
                <h2 className="title gr-text-2 mb-9">Terms & Conditions</h2>
                <p className="gr-text-8 mb-13">Welcome to VASU Training and Skill Development Center. By accessing this website and registering for our courses, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before proceeding with your registration:</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-block bg-default-2 pb-21">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="px-12">
                <div className="single-block mb-11">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Course Registration</h3>
                  <p className="gr-text-9">When registering for a course on our website, you agree to provide accurate and complete information about yourself, including your name, email address, and phone number. You understand and acknowledge that course fees are non-refundable. Payment of course fees includes applicable taxes, including Goods and Services Tax (GST), as mandated by the laws of your jurisdiction.</p>
                </div>
                <div className="single-block mb-13">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Photographs and Publicity
                  </h3>
                  <p className="gr-text-9">During training sessions, photographs or screenshots may be taken for publicity purposes. By attending our courses, you consent to the use of these photographs or screenshots for promotional activities, including posting on social media and websites.</p>
                </div>
                <div className="single-block mb-13">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Course Participation </h3>
                  <p className="gr-text-9">Upon successful registration and payment, you will receive confirmation of your enrollment via email. You are responsible for attending the course sessions as scheduled. VASU Training and Skill Development Center reserves the right to modify course schedules or cancel sessions due to unforeseen circumstances. In such cases, alternative arrangements will be made wherever possible. Participation in course activities, including practical exercises and networking sessions, is encouraged to enhance your learning experience. </p>
                </div>
                <div className="single-block mb-13">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Payment and Refund Policy </h3>
                  <p className="gr-text-9">All payments made through our website for course registration are non-refundable. Once payment is processed, it cannot be refunded under any circumstances. By proceeding with the payment process, you acknowledge and agree that the amount paid is non-refundable.</p>
                </div>
                <div className="single-block mb-13">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Intellectual Property Rights </h3>
                  <p className="gr-text-9">All course materials, including but not limited to presentations, handouts, and online resources, are the intellectual property of VASU Training and Skill Development Center. You agree not to reproduce, distribute, or share these materials without prior written consent. </p>
                </div>
                <div className="single-block mb-13">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Certification </h3>
                  <p className="gr-text-9">Upon successful completion of a course, you will be awarded a certification from VASU Training and Skill Development Center. Certificates are issued based on your attendance and performance during the course. VASU Training and Skill Development Center reserves the right to withhold certification in cases of non-compliance with course requirements or misconduct. </p>
                </div>
                <div className="single-block mb-13">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Privacy Policy </h3>
                  <p className="gr-text-9">We are committed to protecting your privacy and safeguarding your personal information. Please refer to our Privacy Policy for details on how we collect, use, and disclose your data. </p>
                </div>
                <div className="single-block mb-13">
                  <h3 className="gr-text-8 font-weight-bold pb-3">Disclaimer </h3>
                  <p className="gr-text-9">While every effort is made to ensure the accuracy and relevance of course content, VASU Training and Skill Development Center does not guarantee the completeness or suitability of information provided. Course participants are encouraged to seek professional advice where necessary. </p>
                </div>
                <div className="single-block pb-11">
                  <h3 className="gr-text-8 font-weight-bold pb-5">Changes about terms</h3>
                  <p className="gr-text-9 pb-5 mb-0">If we change our terms of use we will post those changes on this page. Registered users will be sent an email that outlines changes made to the terms of use.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default otherPage(Terms); 
