import React, { useState } from 'react';

import baseURL from "../common/base-urls.js";
const CourseSlider = ({ course }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const changeSlide = (n) => {
    let newIndex = currentSlide + n;
    if (newIndex < 0) {
      newIndex = course.course_photos.length - 1;
    } else if (newIndex >= course.course_photos.length) {
      newIndex = 0;
    }
    setCurrentSlide(newIndex);
  };

  return (
    <div className="slider-container">
      {course.course_photos.map((photo, index) => (
        <img
          key={index}
          className={`slider-image rounded-10 w-100 ${index === currentSlide ? 'active-slide' : ''}`}
          src={baseURL.serverImageUrl + photo.photo_name}
          alt="VASU Training and Skill Development Center, VASU Training and Skill Development Center Surat, VASU Training and Skill Development Center Sachin, Hoziwala, EHS training, Environment Health Safety, Safety training, Skill development, Environment regulations, Health regulations, Safety regulations, Safety courses, Safety certifications, EHS certifications, Practical training, On-site training, Environmental legislation, Workplace safety, Occupational health, Safety audit, Risk management"
        />
      ))}
      <a className="prev" onClick={() => changeSlide(-1)}>&#10094;</a>
      <a className="next" onClick={() => changeSlide(1)}>&#10095;</a>
    </div>
  );
};

export default CourseSlider;
