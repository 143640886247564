import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import adminLayout from "../hoc/adminLayout";
import logoimage from "../image/vasu.png";
import axios from "axios";
import baseURL from "../common/base-urls.js";
import "react-select2-wrapper/css/select2.css";
import debounce from "lodash/debounce";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";
import pareshmevawala from "../image/vasutraining/MrPareshMevawala.jpg";
import vasutrainingcenter1 from "../image/vasutraining/10.jpg";
import vasutrainingcenter2 from "../image/vasutraining/9.jpg";
import vasutrainingcenter3 from "../image/vasutraining/3.jpg";
import vasutrainingcenter4 from "../image/vasutraining/8.jpg";
import CourseSlider from "./courseSlider.js";

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [apiUrl, setApiUrl] = useState('');
  const [apiUpcomingUrl, setUpcomingApiUrl] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    console.log(`=======================================`);
    console.log(`========= Updated on ${formattedTime} =========`);
    console.log(`=======================================`);
    console.log(location);
    return () => {
    };
  }, [location]);
  //#region ====================================================== Create State =====================================================
  const [state, setState] = useState({
    delete_id: "0",
    lblpagedisplay: 0,
    total_pages: 0,
    pagination: [],
    show_filter: false,
    filter_applied: false,
    show_toast: false,
    toast_msg: "No record found",
    show_modal: false,
    modal_title: "Confirmation",
    modal_msg:
      "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
    modal_button: "Delete",
  });
  //#endregion
  //#region ====================================================== Update State ======================================================
  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  //#endregion 
  //#region ====================================================== Page Table Bind Data ======================================================
  let tbldata = {};
  let tblupcomingdata = {};
  let tblpagination = {};
  useEffect(() => {

    const queryParams = {
      page: page,
      limit: limit
    };
    const newApiUrl = `course/web?institute_id=1`;
    setApiUrl(newApiUrl);
    const newupcomingApiUrl = `course/webupcoming?institute_id=1`;
    setUpcomingApiUrl(newupcomingApiUrl);


  }, []);
  const { data, error, isValidating } = useSWR(
    apiUrl,
    () => getAPI(apiUrl, false),
    {
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
    }
  );
  tbldata = data;

  const { data: newdata, error1, isValidating1 } = useSWR(
    apiUpcomingUrl,
    () => getAPI(apiUpcomingUrl, false),
    {
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
    }
  );
  tblupcomingdata = newdata;


  //#endregion
  //#region ====================================================== Pagination & Page Changed ======================================================
  useEffect(() => {
    try {
      if (tbldata && tbldata.data && tbldata.data.hasOwnProperty("count") && tbldata.data.count > 0) {
        let dblPageCount = Math.ceil(parseInt(tbldata.data.count) / limit);
        let pageCount = Math.max(1, dblPageCount);
        updateState("total_pages", pageCount);
        let rows = [];
        if (pageCount > 0) {
          for (let i = 1; i <= pageCount; i++) {
            if (i == 1 || i == pageCount) {
              rows.push({ text: i.toString(), value: i.toString(), enabled: i != parseInt(page) });
            }
            else if ((i >= parseInt(page) - 2) && (i <= parseInt(page) + 3)) {
              rows.push({ text: i.toString(), value: i.toString(), enabled: i != parseInt(page) });
            }
            else if (((i > parseInt(page) + 3) && (i >= pageCount - 2))) {
              rows.push({ text: i.toString(), value: i.toString(), enabled: i != parseInt(page) });
            }
          }
        } else {
          pageCount = '0';
        }
        setState((prevState) => ({
          ...prevState,
          pagination: rows
        }));
        tblpagination = rows;
        let firstpage = 1;
        let lastpage = 1;
        if (parseInt(page) === 1) {
          if (limit * parseInt(page) <= parseInt(tbldata.data.count)) {
            firstpage = 1;
            lastpage = limit;
          } else {
            firstpage = 1;
            lastpage = parseInt(tbldata.data.count);
          }
        } else {
          if (limit * parseInt(page) <= parseInt(tbldata.data.count)) {
            firstpage = limit * (parseInt(page) - 1) + 1;
            lastpage = limit * parseInt(page);
          } else {
            lastpage = parseInt(tbldata.data.count);
            firstpage = limit * (parseInt(page) - 1) + 1;
          }
        }
        updateState("lblpagedisplay", tbldata.data.count !== '0' ? `${firstpage} - ${lastpage}` : '0 - 0');
        if (lastpage === 0) {
          firstpage = 0;
          updateState("lblpagedisplay", tbldata.data.count !== '0' ? `${firstpage} - ${lastpage}` : '0 - 0');
        }
      }
    } catch (ex) {
    }
  }, [data]);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  //#endregion  
  const addRecord = () => {
    try {
      navigate("admin.vasutsdc.in");
    } catch (err) { }
  };
  const debouncedAddRecord = debounce(addRecord, 500);
  //#endregion 
  //#region ====================================================== Get API ======================================================
  const getAPI = async (url, Auth) => {
    try {

      const response = await axios.get(`${baseURL.serverUrl}${url}`, {
        headers: {
        },
      });
      return response.data;

    } catch (err) {
      return {};
    }
  };
  //#endregion 
  useEffect(() => {
    document.querySelectorAll('a[href^="/"], a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        const href = this.getAttribute('href');
        const id = href.startsWith('#') ? href.substring(1) : href; // Remove the leading slash or hash

        if (id === '/privacy' || id === '/terms') {
          // Navigate to privacy or terms page
          window.location.href = window.location.origin + '#/' + id.substring(1);
          // Scroll to the top of the page
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
        else if (id === '/') {
          // Navigate to privacy or terms page
          window.location.href = window.location.origin;
          // Scroll to the top of the page
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
        else if (id !== '') {
          // Scroll to the element with the corresponding ID
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth'
            });
          }
        } else {
          // Handle routing for React Router links
          window.location.href = href;
        }
      });
    });
  }, []);





  return (
    <>
      <div className="bg-image bg-gradient bg-gradient-black pb-17 pt-24 pt-lg-32 pb-lg-28 backimg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-9 col-lg-7 col-xl-6" data-aos="fade-right" data-aos-duration="500" data-aos-once="true">
              <div className="hero-content section-title dark-mode-texts">
                <h1 className="gr-text-2 font-weight-bold mb-8">Empowering Excellence in Environment, Health & Safety</h1>
                <p className="gr-text-8 pr-sm-7 pr-md-12">At VASU Training and Skill Development Center, we are dedicated to fostering a culture of safety consciousness and professional excellence in Environment, Health & Safety (EHS) practices.</p>
                <div className="hero-btns mt-10">
                  <a href="https://join.vasutsdc.in/#/register" target="_blank" className="btn btn-primary with-icon gr-hover-y">Enroll Now<i className="icon icon-tail-right font-weight-bold"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="alert-section py-9 bg-blackish-blue dark-mode-texts ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10 col-sm-9 col-10">
              <div className="alert-content d-flex flex-column flex-lg-row align-items-center justify-content-lg-center text-center">
                <span className="d-inline-flex mr-2 gr-text-color">
                  <i className="far fa-arrow-circle-right gr-text-9"></i>
                </span>
                <span className="alert-text gr-text-9 gr-text-color">Curious to know more about courses?  <a data-fancybox href="#upcomingcourses" className="action-link gr-text-color gr-text-underline">Check it out</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {tblupcomingdata &&
        (!tblupcomingdata.data || !tblupcomingdata.data.courses?.length) ? (
        <>
        </>
      ) : (
        <>
          <div className="service-section bg-default-6 pt-12 py-lg-23 paddingbottom0" id="upcomingcourses">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8 col-md-10">
                  <div className="section-title text-center mb-11 mb-lg-15">
                    <h2 className="title gr-text-4 mb-6">Join upcoming courses</h2>
                    <p className="gr-text-8 mb-0 px-lg-7 px-xl-0" style={{
                      textAlign: 'center',
                    }}>Explore our upcoming courses and join us to unlock new skills, and embark on a path to personal and professional growth</p>
                  </div>
                </div>
              </div>
              <div className="row align-items-center justify-content-center aligntop">
                {tblupcomingdata &&
                  tblupcomingdata.data.courses.map((x1) => (
                    <>
                      <div key={x1.course_id} className="col-lg-6 col-md-6 col-sm-10 mb-13">
                        <div className="service-card">
                          <h3 className="card-title gr-text-7 mb-7">{x1.course_name}</h3>
                          <div className="img-thumb mb-8">
                            <CourseSlider course={x1} />
                          </div>
                          <p className="excerpt gr-text-9 mb-6 regamount">
                            <span className="regcolumn">
                              <span className="gr-text-9">
                                ₹ <span className="gr-text-8">{x1.p_amount}</span> For professionals
                              </span>
                              <span className="gr-text-9">
                                ₹ <span className="gr-text-8">{x1.s_amount}</span> For Students
                              </span>
                              <span className="gr-text-9">
                                Unlock extra discounts at checkout when booking for multiple seats, perfect for groups joining together.
                              </span>
                            </span>
                            <a href="https://join.vasutsdc.in/#/register" target="_blank" className="btn btn-primary gr-hover-y btnregister">Join</a>
                          </p>
                          <p className="excerpt gr-text-9 mb-6">
                            {x1.description}
                          </p>
                          <a href="#contact" className="btn-link with-icon gr-text-9 font-weight-bold">{x1.course_type.course_type_name}</a>
                        </div>
                      </div>
                    </>
                  ))}

              </div>
            </div>
          </div>
        </>
      )}
      <div className="content-section pt-12 pb-10 bg-default-6 py-xl-25 ">
        <div className="container">
          <div className="row align-items-center justify-content-md-center">
            <div className="col-lg-4 col-md-10">
              <div className="content-text text-center text-lg-left mb-9 mb-lg-0 pr-xl-11">
                <h2 className="title gr-text-5 mb-8">Expert Guidance for Your Journey</h2>
                <p className="gr-text-8 mb-9">Benefit from the leadership of seasoned professionals with extensive expertise in Environment, Health & Safety (EHS) practices.</p>
                <a href="#courses" className="btn btn-primary gr-hover-y">Course offerings</a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="content-img rounded-8">
                <img className="w-100 rounded-8" src={pareshmevawala} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="stats-wrapper mt-6 mt-md-0 pl-xl-9">
                <div className="single-stat py-6" data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
                  <h3 className="title gr-text-5 mb-5"><span>10,000</span>+</h3>
                  <p className="gr-text-8 mb-0">
                    Individuals we have helped till date to achieve their goals.
                  </p>
                </div>
                <div className="single-stat py-6" data-aos="fade-left" data-aos-duration="1200" data-aos-once="true">
                  <h3 className="title gr-text-5 mb-5"><span>92</span>%</h3>
                  <p className="gr-text-8 mb-0">Satisfaction rate comes from our valued Individuals.</p>
                </div>
                <div className="single-stat py-6" data-aos="fade-left" data-aos-duration="1800" data-aos-once="true">
                  <h3 className="title gr-text-5 mb-5"><span>4.9</span>/5.0</h3>
                  <p className="gr-text-8 mb-0">Impressive average Individuals ratings across the web.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about" className="content-section2 pt-13 pt-lg-17 bg-default-2 overflow-hidden topp0 lgback">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-4">
              <img className="w-100" src={vasutrainingcenter1} alt="" />
              <img className="w-100" src={vasutrainingcenter2} alt="" />
              <img className="w-100" src={vasutrainingcenter3} alt="" />
              <img className="w-100" src={vasutrainingcenter4} alt="" />
            </div>
            <div className="col-lg-6 offset-lg-1 col-md-8 px-xl-11 mt-9 mt-lg-0" data-aos="fade-left" data-aos-duration="700" data-aos-once="true">
              <div className="content-text pr-xl-13 mb80">
                <div className="brand-logo mb-7">
                  <img src={logoimage} className="logowidth" />
                </div>
                <p className="gr-text-8 mb-0">At VASU Training and Skill Development Center, we are dedicated to leading the way in excellence in environmental, health, and safety (EHS) education. As a premier provider of specialized training and development courses, we are committed to empowering individuals and organizations to thrive in the ever-evolving landscape of safety and sustainability.</p>

                <p className="gr-text-8 mb-0 mt25">
                  Founded with the vision of promoting a safer and healthier world, VASU Training and Skill Development Center operates under the esteemed banner of quality education and empowerment.
                </p>
                <p className="gr-text-8 mb-0 mt25">
                  Whether you're seeking to deepen your understanding of environmental regulations, enhance your risk assessment skills, or develop emergency preparedness plans, VASU Training and Skill Development Center offers a comprehensive range of courses designed to equip you with the knowledge and expertise needed to make a positive impact.
                </p>
              </div>

              <div className="content-text pr-xl-13">
                <h2 className="title gr-text-4 mb-7">Reasons to choose VASU Training and Skill Development Center</h2>
                <p className="gr-text-8 mb-0">Experience industry-relevant content curated by seasoned professionals, engage in practical skill-building exercises for immediate application, seize invaluable networking opportunities, and earn recognized certifications upon completion. Join us today and propel your career in environmental, health, and safety (EHS) to new heights.</p>
              </div>

              <div className="content-widget mt-9 mb-11 mb-lg-0">
                <div className="row">
                  <div className="col-md-6">
                    <div className="single-widget d-flex align-items-center py-5">
                      <div className="widget-icon text-primary mr-6 gr-text-8">
                        <i className="icon icon-check-simple"></i>
                      </div>
                      <h3 className="w-title gr-text-11 font-weight-bold mb-0">Industry-Relevant Content</h3>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-widget d-flex align-items-center py-5">
                      <div className="widget-icon text-primary mr-6 gr-text-8">
                        <i className="icon icon-check-simple"></i>
                      </div>
                      <h3 className="w-title gr-text-11 font-weight-bold mb-0">Practical Approach</h3>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-5">
                    <div className="single-widget d-flex align-items-center py-5">
                      <div className="widget-icon text-primary mr-6 gr-text-8">
                        <i className="icon icon-check-simple"></i>
                      </div>
                      <h3 className="w-title gr-text-11 font-weight-bold mb-0">Networking Opportunities</h3>
                    </div>
                  </div>
                  <div className="col-xl-6 col-md-7">
                    <div className="single-widget d-flex align-items-center py-5">
                      <div className="widget-icon text-primary mr-6 gr-text-8">
                        <i className="icon icon-check-simple"></i>
                      </div>
                      <h3 className="w-title gr-text-11 font-weight-bold mb-0">Certification</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="service-section bg-default-6 pt-12 py-lg-23" id="courses">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="section-title text-center mb-11 mb-lg-15">
                <h2 className="title gr-text-4 mb-6">Recent courses</h2>

                <p className="gr-text-8 mb-0 px-lg-7 px-xl-0" style={{
                      textAlign: 'center',
                    }}>We understand the unique challenges and prospects within the industry. We can help you grow and not remain where you are.</p>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-center aligntop">
            {tbldata &&
              (!tbldata.data || !tbldata.data.courses?.length) ? (
              <>
                {/* <div className="norecordcss">
                  <img
                    src={emptyrecord}
                    width="330px"
                    alt="No record found"
                  />
                  <div className="norecordfont">No record found</div>
                </div> */}
              </>
            ) : (
              <>
                {tbldata &&
                  tbldata.data.courses.map((x) => (
                    <>
                      <div key={x.course_id} className="col-lg-6 col-md-6 col-sm-10 mb-13">
                        <div className="service-card">

                          <div className="img-thumb mb-8">
                            <CourseSlider course={x} />
                          </div>
                          <h3 className="card-title gr-text-7 mb-7">{x.course_name}</h3>
                          <p className="excerpt gr-text-9 mb-6">
                            {x.description}
                          </p>
                          <a href="#contact" className="btn-link with-icon gr-text-9 font-weight-bold">{x.course_type.course_type_name}</a>
                        </div>
                      </div>

                    </>
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="video-section bg-image bg-overlay overlay-1 pt-lg-21 pb-lg-21 pt-12 pb-8 dark-mode-texts testiback" data-aos="fade-top" data-aos-duration="800" data-aos-once="true">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="section-title text-center mb-11 mb-lg-14">
                <h2 className="title gr-text-4 mb-6">What people say about us</h2>
                <p className="gr-text-8 mb-0 px-lg-7 px-xl-0" style={{
                      textAlign: 'center',
                    }}>Trusted by over 10,000 satisfied professionals worldwide, VASU Training and Skill Development Center has played a pivotal role in empowering individuals and organizations to excel in environmental, health, and safety practices.</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-around">
            <div className="col-lg-4 col-md-6 mb-9">
              <div className="card rounded-10 border p-9 h-100">
                <div className="customer-img circle-sm-2 mb-9 mb-lg-14">
                  {/* <img src="image/vasu_training/vasu1.jpg" alt="" className="w-100"> */}
                </div>
                <p className="review-text gr-text-7 text-blackish-blue mb-7 mb-lg-8">“VASU Training and Skill Development Center enhanced my understanding of safety practices, significantly improving my performance at work.”</p>
                <div className="customer-identity d-md-flex align-items-center mt-auto">
                  <h3 className="name gr-text-9 mb-2 mb-md-0 mr-3 text-blackish-blue">Nidhi Lad</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-9">
              <div className="card rounded-10 border p-9 h-100">
                <div className="customer-img circle-sm-2 mb-9 mb-lg-14">
                  {/* <img src="image/vasu_training/vasu2.jpg" alt="" /> */}
                </div>
                <p className="review-text gr-text-7 text-blackish-blue mb-7 mb-lg-8">“Attending VASU Training and Skill Development Center's courses expanded my knowledge base and provided practical insights applicable to real-world scenarios.”</p>
                <div className="customer-identity d-md-flex align-items-center mt-auto">
                  <h3 className="name gr-text-9 mb-2 mb-md-0 mr-3 text-blackish-blue">Jay Malaviya</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-9">
              <div className="card rounded-10 border p-9 h-100">
                <div className="customer-img circle-sm-2 mb-9 mb-lg-14">
                  {/* <img src="image/vasu_training/vasu3.jpg" alt="" /> */}
                </div>
                <p className="review-text gr-text-7 text-blackish-blue mb-7 mb-lg-8">“Thanks to VASU Training and Skill Development Center, I gained confidence in implementing safety protocols and contributing to a safer work environment.”</p>
                <div className="customer-identity d-md-flex align-items-center mt-auto">
                  <h3 className="name gr-text-9 mb-2 mb-md-0 mr-3 text-blackish-blue">Krisha Patel</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default adminLayout(Index); 
