
export default {
  // images: 'https://d2mjqh1irji0ds.cloudfront.net/',
  // videos: 'https://d1xjufsjr32fce.cloudfront.net/',
  // dash: 'https://d2ehf0c0g5fbv5.cloudfront.net/',
  // sounds: 'https://d1yggmyji954u4.cloudfront.net/',
  // serverUrl: 'http://192.168.29.25:5012/api/',
  serverUrl: 'https://vasutsdc.in/api/',
  serverImageUrl: 'https://friendsupapp.s3.ap-south-1.amazonaws.com/vasucourse/',
  //  serverUrl: 'http://192.168.29.25:5002/api/',
  // serverUrl: 'http://192.168.29.21:3000/',
  // firstFragmentImage : "https://dgjclr5i3tmzz.cloudfront.net/smis-transform/cm_front,w_426,h_270/",

  // s3BucketImages: "https://vasu-images.s3.ap-south-1.amazonaws.com/",
  // s3BucketSounds: "https://vasu-sounds.s3.ap-south-1.amazonaws.com/",
  // s3BucketVideos: "https://vasu-videos.s3.ap-south-1.amazonaws.com/",
  // s3BucketDocuments: "https://vasu-documents.s3.ap-south-1.amazonaws.com/",
  // s3BucketModels: "https://vasu-3d-models.s3.ap-south-1.amazonaws.com/",
  // baseUrlModels: "https://d37gt58m4pkn19.cloudfront.net/",
  // baseUrlDocuments: "https://d3eb5op7vv146t.cloudfront.net/"
};  
